<template>
  <div class="download">
    <div class="_title">下载中心</div>
    <div class="_content">
      <!--      <div class="wrap">-->
      <!--        <div class="flex">-->
      <!--          <p class="desc-title">-->
      <!--             <a href="https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/abstract/202109/会议延期通知.pdf"  target="view_window" download='会议延期通知'>会议延期通知</a>-->
      <!--            </p>-->
      <!--          <p class="desc">-->
      <!--           2023-10-26-->
      <!--          </p>-->
      <!--        </div>-->
      <!--        <div class="line"></div>-->
      <!--      </div>-->

      <!--      <div class="wrap">-->
      <!--        <div class="flex">-->
      <!--          <p class="desc-title">-->
      <!--             <a href="https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/abstract/202109/%E4%BC%9A%E6%9C%9F%E5%8F%98%E6%9B%B4%E9%80%9A%E7%9F%A5.pdf"  target="view_window" download='会期变更通知'>会期变更通知</a>-->
      <!--            </p>-->
      <!--          <p class="desc">-->
      <!--           2023-10-26-->
      <!--          </p>-->
      <!--        </div>-->
      <!--        <div class="line"></div>-->
      <!--      </div>-->

      <div class="wrap">
        <div class="flex">
          <p class="desc-title">
            <a
              href="https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2023/abstract/2023%E5%88%86%E5%AD%90%E6%A4%8D%E7%89%A9%E8%82%B2%E7%A7%8D%E5%A4%A7%E4%BC%9A%EF%BC%88%E7%AC%AC%E4%B8%80%E8%BD%AE%EF%BC%89%E4%BC%9A%E8%AE%AE%E9%80%9A%E7%9F%A5(%E7%9B%96%E7%AB%A0%E7%89%88).pdf"
              target="view_window"
              download="第一轮会议通知（盖章版）"
              >第一轮会议通知（盖章版）</a
            >
          </p>
          <p class="desc">
            2023-10-26
          </p>
        </div>
        <div class="line"></div>
      </div>

      <!--      <div class="wrap">-->
      <!--        <div class="flex">-->
      <!--          <p class="desc-title">-->
      <!--            <a href="https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/plugs/MPBC2021-%E5%A2%99%E6%8A%A5%E6%A8%A1%E6%9D%BF.pptx"  target="view_window"  download='墙报模板'>墙报模板</a>-->
      <!--          </p>-->
      <!--          <p class="desc">-->
      <!--             2023-10-26-->
      <!--          </p>-->
      <!--        </div>-->
      <!--        <div class="line"></div>-->
      <!--      </div>-->

      <div class="wrap">
        <div class="flex">
          <p class="desc-title">
            <a
              href="https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2023/plugs/MPBC2023-%E8%AE%BA%E6%96%87%E6%91%98%E8%A6%81%E6%A8%A1%E6%9D%BF.docx"
              target="view_window"
              download="摘要模板"
              >摘要模板</a
            >
          </p>
          <p class="desc">
            2023-10-26
          </p>
        </div>
        <div class="line"></div>
      </div>

      <div class="wrap">
        <div class="flex">
          <p class="desc-title">
            <a
              href="https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2023/abstract/2023%E5%88%86%E5%AD%90%E6%A4%8D%E7%89%A9%E8%82%B2%E7%A7%8D%E5%A4%A7%E4%BC%9A%EF%BC%88%E7%AC%AC%E4%BA%8C%E8%BD%AE%EF%BC%89%E4%BC%9A%E8%AE%AE%E9%80%9A%E7%9F%A5(%E7%9B%96%E7%AB%A0%E7%89%88%EF%BC%89.pdf"
              target="view_window"
              download="2023分子植物育种大会（第二轮）会议通知（盖章版）.pdf"
              >2023分子植物育种大会（第二轮）会议通知（盖章版）</a
            >
          </p>
          <p class="desc">
            2023-12-07
          </p>
        </div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style lang="less" scoped>
.download {
  padding: 0 50px;
  padding-bottom: 150px;

  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  .desc-title {
    font-weight: bold;
    color: #024a85;
    font-size: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    a {
      text-decoration: none;
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .desc {
    color: #7f7f7f;
    font-size: 16px;
  }
  .line {
    background: #e5e5e5;
    height: 1px;
    width: 100%;
    margin-top: 10px;
  }
  .wrap {
    margin-top: 50px;
    line-height: 20px;
  }
}
</style>
